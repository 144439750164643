/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';
import sneakerSurge from '../../assets/img/brand/sneaker-surge.png';

class AdminNavbar extends React.PureComponent {
  render() {
    return (
      <Navbar
        className="navbar-top navbar-horizontal navbar-dark bg-light"
        expand="md"
      >
        <Container className="px-4 justify-content-center">
          <NavbarBrand to="/" tag={Link}>
            <img alt="Sneaker Surge Logo" style={{ height: 24 }} src={sneakerSurge} />
          </NavbarBrand>
        </Container>
      </Navbar>
    );
  }
}

export default AdminNavbar;
