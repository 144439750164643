/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import {
  faCalendar, faCog, faQuestion, faSignOutAlt, faUser,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
import { NavLink as NavLinkRRD, Link, Route } from 'react-router-dom';
// nodejs library to set properties for components

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import httpClient from 'api';
import useRoutes from 'hooks/useRoutes';
import SidebarDropdownItem from './SidebarDropdownItem';
import team1Image from '../../assets/img/theme/team-1-800x800.jpg';
import { RouteType } from '../../../custom';

let ps;
type Props = {
  location: {
    pathname: string;
  };
  bgColor?: string;
  routes: RouteType;
  logo: {
    innerLink?: string;
    outterLink?: string;
    imgSrc: string;
    imgAlt: string;
  };
};
class Sidebar extends React.Component<Props, { collapseOpen: boolean }> {
  constructor(props: Props) {
    super(props);
    this.activeRoute.bind(this);
    this.state = {
      collapseOpen: false,
    };
  }

  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState(({ collapseOpen }) => ({
      collapseOpen: !collapseOpen,
    }));
  };

  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };

  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes: RouteType) => routes.filter(({ hide }) => !hide).map((prop, key) => {
    if ('subMenu' in prop) {
      return (
        <SidebarDropdownItem
          subMenu={(prop as any).subMenu as any}
          key={prop.name}
          {...prop}
          path={prop.path!}
          component={prop.component!}
        />
      );
    }
    return (
      <NavItem key={prop.layout + prop.path}>
        <NavLink
          to={prop.layout + prop.path}
          tag={NavLinkRRD}
          onClick={this.closeCollapse}
          activeClassName="active"
        >
          <FontAwesomeIcon icon={prop.faIcon} style={{ width: 20, marginRight: 16 }} />
          {prop.name}
        </NavLink>
      </NavItem>
    );
  });

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName: string) {
    const { location } = this.props;
    return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }

  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: '_blank',
      };
    }
    const { collapseOpen } = this.state;
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <>
              <NavbarBrand className="pt-0 d-none d-md-block" {...navbarBrandProps}>
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
              </NavbarBrand>
              <NavbarBrand className="pt-0 w-50 d-none d-sm-block d-md-none" {...navbarBrandProps}>
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
              </NavbarBrand>
              <NavbarBrand className="pt-0 w-25 d-block d-sm-none" {...navbarBrandProps}>
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
              </NavbarBrand>
            </>
          ) : null}
          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav className="nav-link-icon">
                <i className="ni ni-bell-55" />
              </DropdownToggle>
              <DropdownMenu
                aria-labelledby="navbar-default_dropdown_1"
                className="dropdown-menu-arrow"
                right
              >
                <DropdownItem>Action</DropdownItem>
                <DropdownItem>Another action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Something else here</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img alt="..." src={team1Image} />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem
                  href="#"
                  onClick={async (e) => {
                    e.preventDefault();
                    httpClient.auth.logout();
                    window.location.assign('/auth/login');
                  }}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            <Form className="mt-4 mb-3 d-md-none">
              <InputGroup className="input-group-rounded input-group-merge">
                <Input
                  aria-label="Search"
                  className="form-control-rounded form-control-prepended"
                  placeholder="Search"
                  type="search"
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <span className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Form>
            {/* Navigation */}
            <Nav navbar>{this.createLinks(routes)}</Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default (props: Props) => {
  const routes = useRoutes();

  return <Sidebar {...props} routes={routes} />;
};
